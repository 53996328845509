import Layout from "./components/layout/Layout";


function App() {
  return (
    <Layout>
      
    </Layout>
  );
}

export default App;
