import classes from './Measurement.module.css';

const bloodpressures = [
  {
    "Id": 1,
    "Date": "10-Sep-2022",
    "Time": "05:44 PM",
    "Systolic": "142 mmHg",
    "Diastolic": "87 mmHg",
    "Pulse": "86 bpm",
    "Classification": "Systolic Hypertension",
    "Data": "Irregular Heartbeat"
  },
  {
    "Id": 2,
    "Date": "10-Sep-2022",
    "Time": "05:45 PM",
    "Systolic": "138 mmHg",
    "Diastolic": "96 mmHg",
    "Pulse": "86 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 3,
    "Date": "10-Sep-2022",
    "Time": "05:46 PM",
    "Systolic": "139 mmHg",
    "Diastolic": "95 mmHg",
    "Pulse": "85 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 4,
    "Date": "11-Sep-2022",
    "Time": "10:12 AM",
    "Systolic": "153 mmHg",
    "Diastolic": "103 mmHg",
    "Pulse": "58 bpm",
    "Classification": "High BP - Stage 2",
    "Data": ""
  },
  {
    "Id": 5,
    "Date": "11-Sep-2022",
    "Time": "10:13 AM",
    "Systolic": "145 mmHg",
    "Diastolic": "97 mmHg",
    "Pulse": "59 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 6,
    "Date": "11-Sep-2022",
    "Time": "10:15 AM",
    "Systolic": "146 mmHg",
    "Diastolic": "94 mmHg",
    "Pulse": "61 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 7,
    "Date": "11-Sep-2022",
    "Time": "09:53 PM",
    "Systolic": "154 mmHg",
    "Diastolic": "105 mmHg",
    "Pulse": "74 bpm",
    "Classification": "High BP - Stage 2",
    "Data": ""
  },
  {
    "Id": 8,
    "Date": "11-Sep-2022",
    "Time": "09:55 PM",
    "Systolic": "151 mmHg",
    "Diastolic": "103 mmHg",
    "Pulse": "70 bpm",
    "Classification": "High BP - Stage 2",
    "Data": ""
  },
  {
    "Id": 9,
    "Date": "11-Sep-2022",
    "Time": "09:57 PM",
    "Systolic": "148 mmHg",
    "Diastolic": "104 mmHg",
    "Pulse": "74 bpm",
    "Classification": "High BP - Stage 2",
    "Data": ""
  },
  {
    "Id": 10,
    "Date": "12-Sep-2022",
    "Time": "06:45 AM",
    "Systolic": "147 mmHg",
    "Diastolic": "99 mmHg",
    "Pulse": "73 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 11,
    "Date": "12-Sep-2022",
    "Time": "06:47 AM",
    "Systolic": "148 mmHg",
    "Diastolic": "101 mmHg",
    "Pulse": "69 bpm",
    "Classification": "High BP - Stage 2",
    "Data": ""
  },
  {
    "Id": 12,
    "Date": "12-Sep-2022",
    "Time": "06:48 AM",
    "Systolic": "146 mmHg",
    "Diastolic": "100 mmHg",
    "Pulse": "73 bpm",
    "Classification": "High BP - Stage 2",
    "Data": ""
  },
  {
    "Id": 13,
    "Date": "12-Sep-2022",
    "Time": "10:01 PM",
    "Systolic": "155 mmHg",
    "Diastolic": "96 mmHg",
    "Pulse": "86 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 14,
    "Date": "12-Sep-2022",
    "Time": "10:03 PM",
    "Systolic": "168 mmHg",
    "Diastolic": "98 mmHg",
    "Pulse": "86 bpm",
    "Classification": "High BP - Stage 2",
    "Data": "Irregular Heartbeat"
  },
  {
    "Id": 15,
    "Date": "12-Sep-2022",
    "Time": "10:04 PM",
    "Systolic": "148 mmHg",
    "Diastolic": "98 mmHg",
    "Pulse": "89 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 16,
    "Date": "13-Sep-2022",
    "Time": "12:45 AM",
    "Systolic": "146 mmHg",
    "Diastolic": "102 mmHg",
    "Pulse": "81 bpm",
    "Classification": "High BP - Stage 2",
    "Data": ""
  },
  {
    "Id": 17,
    "Date": "13-Sep-2022",
    "Time": "09:52 AM",
    "Systolic": "148 mmHg",
    "Diastolic": "101 mmHg",
    "Pulse": "64 bpm",
    "Classification": "High BP - Stage 2",
    "Data": ""
  },
  {
    "Id": 18,
    "Date": "13-Sep-2022",
    "Time": "09:54 AM",
    "Systolic": "140 mmHg",
    "Diastolic": "98 mmHg",
    "Pulse": "67 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 19,
    "Date": "13-Sep-2022",
    "Time": "09:55 AM",
    "Systolic": "148 mmHg",
    "Diastolic": "101 mmHg",
    "Pulse": "69 bpm",
    "Classification": "High BP - Stage 2",
    "Data": ""
  },
  {
    "Id": 20,
    "Date": "13-Sep-2022",
    "Time": "09:54 PM",
    "Systolic": "134 mmHg",
    "Diastolic": "97 mmHg",
    "Pulse": "75 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 21,
    "Date": "13-Sep-2022",
    "Time": "09:55 PM",
    "Systolic": "134 mmHg",
    "Diastolic": "94 mmHg",
    "Pulse": "73 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 22,
    "Date": "13-Sep-2022",
    "Time": "09:57 PM",
    "Systolic": "137 mmHg",
    "Diastolic": "96 mmHg",
    "Pulse": "74 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 23,
    "Date": "14-Sep-2022",
    "Time": "08:08 AM",
    "Systolic": "137 mmHg",
    "Diastolic": "90 mmHg",
    "Pulse": "65 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 24,
    "Date": "14-Sep-2022",
    "Time": "08:09 AM",
    "Systolic": "136 mmHg",
    "Diastolic": "94 mmHg",
    "Pulse": "61 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 25,
    "Date": "14-Sep-2022",
    "Time": "08:10 AM",
    "Systolic": "143 mmHg",
    "Diastolic": "93 mmHg",
    "Pulse": "61 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 26,
    "Date": "14-Sep-2022",
    "Time": "11:33 PM",
    "Systolic": "149 mmHg",
    "Diastolic": "98 mmHg",
    "Pulse": "60 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 27,
    "Date": "14-Sep-2022",
    "Time": "11:34 PM",
    "Systolic": "156 mmHg",
    "Diastolic": "99 mmHg",
    "Pulse": "62 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 28,
    "Date": "14-Sep-2022",
    "Time": "11:35 PM",
    "Systolic": "150 mmHg",
    "Diastolic": "99 mmHg",
    "Pulse": "62 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 29,
    "Date": "15-Sep-2022",
    "Time": "09:25 AM",
    "Systolic": "140 mmHg",
    "Diastolic": "94 mmHg",
    "Pulse": "67 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 30,
    "Date": "15-Sep-2022",
    "Time": "09:26 AM",
    "Systolic": "131 mmHg",
    "Diastolic": "90 mmHg",
    "Pulse": "67 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 31,
    "Date": "15-Sep-2022",
    "Time": "09:27 AM",
    "Systolic": "131 mmHg",
    "Diastolic": "91 mmHg",
    "Pulse": "67 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 32,
    "Date": "15-Sep-2022",
    "Time": "09:49 PM",
    "Systolic": "140 mmHg",
    "Diastolic": "88 mmHg",
    "Pulse": "68 bpm",
    "Classification": "Systolic Hypertension",
    "Data": ""
  },
  {
    "Id": 33,
    "Date": "15-Sep-2022",
    "Time": "09:50 PM",
    "Systolic": "150 mmHg",
    "Diastolic": "104 mmHg",
    "Pulse": "69 bpm",
    "Classification": "High BP - Stage 2",
    "Data": ""
  },
  {
    "Id": 34,
    "Date": "15-Sep-2022",
    "Time": "09:51 PM",
    "Systolic": "146 mmHg",
    "Diastolic": "95 mmHg",
    "Pulse": "65 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 35,
    "Date": "16-Sep-2022",
    "Time": "08:24 AM",
    "Systolic": "137 mmHg",
    "Diastolic": "93 mmHg",
    "Pulse": "68 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 36,
    "Date": "16-Sep-2022",
    "Time": "08:25 AM",
    "Systolic": "141 mmHg",
    "Diastolic": "91 mmHg",
    "Pulse": "67 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 37,
    "Date": "16-Sep-2022",
    "Time": "08:26 AM",
    "Systolic": "142 mmHg",
    "Diastolic": "88 mmHg",
    "Pulse": "66 bpm",
    "Classification": "Systolic Hypertension",
    "Data": ""
  },
  {
    "Id": 38,
    "Date": "16-Sep-2022",
    "Time": "10:12 PM",
    "Systolic": "139 mmHg",
    "Diastolic": "88 mmHg",
    "Pulse": "79 bpm",
    "Classification": "High Normal",
    "Data": ""
  },
  {
    "Id": 39,
    "Date": "16-Sep-2022",
    "Time": "10:13 PM",
    "Systolic": "136 mmHg",
    "Diastolic": "87 mmHg",
    "Pulse": "79 bpm",
    "Classification": "High Normal",
    "Data": ""
  },
  {
    "Id": 40,
    "Date": "16-Sep-2022",
    "Time": "10:14 PM",
    "Systolic": "138 mmHg",
    "Diastolic": "88 mmHg",
    "Pulse": "81 bpm",
    "Classification": "High Normal",
    "Data": ""
  },
  {
    "Id": 41,
    "Date": "17-Sep-2022",
    "Time": "10:16 AM",
    "Systolic": "132 mmHg",
    "Diastolic": "90 mmHg",
    "Pulse": "67 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 42,
    "Date": "17-Sep-2022",
    "Time": "10:17 AM",
    "Systolic": "130 mmHg",
    "Diastolic": "95 mmHg",
    "Pulse": "68 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 43,
    "Date": "17-Sep-2022",
    "Time": "10:18 AM",
    "Systolic": "125 mmHg",
    "Diastolic": "88 mmHg",
    "Pulse": "65 bpm",
    "Classification": "High Normal",
    "Data": ""
  },
  {
    "Id": 44,
    "Date": "17-Sep-2022",
    "Time": "08:51 PM",
    "Systolic": "144 mmHg",
    "Diastolic": "93 mmHg",
    "Pulse": "78 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 45,
    "Date": "17-Sep-2022",
    "Time": "08:52 PM",
    "Systolic": "143 mmHg",
    "Diastolic": "91 mmHg",
    "Pulse": "78 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 46,
    "Date": "17-Sep-2022",
    "Time": "08:53 PM",
    "Systolic": "137 mmHg",
    "Diastolic": "87 mmHg",
    "Pulse": "78 bpm",
    "Classification": "High Normal",
    "Data": ""
  },
  {
    "Id": 47,
    "Date": "22-Sep-2022",
    "Time": "12:08 AM",
    "Systolic": "173 mmHg",
    "Diastolic": "107 mmHg",
    "Pulse": "70 bpm",
    "Classification": "High BP - Stage 2",
    "Data": ""
  },
  {
    "Id": 48,
    "Date": "22-Sep-2022",
    "Time": "12:10 AM",
    "Systolic": "164 mmHg",
    "Diastolic": "107 mmHg",
    "Pulse": "70 bpm",
    "Classification": "High BP - Stage 2",
    "Data": ""
  },
  {
    "Id": 49,
    "Date": "22-Sep-2022",
    "Time": "12:11 AM",
    "Systolic": "156 mmHg",
    "Diastolic": "100 mmHg",
    "Pulse": "69 bpm",
    "Classification": "High BP - Stage 2",
    "Data": ""
  },
  {
    "Id": 50,
    "Date": "22-Sep-2022",
    "Time": "08:21 AM",
    "Systolic": "149 mmHg",
    "Diastolic": "94 mmHg",
    "Pulse": "62 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 51,
    "Date": "22-Sep-2022",
    "Time": "08:22 AM",
    "Systolic": "149 mmHg",
    "Diastolic": "100 mmHg",
    "Pulse": "64 bpm",
    "Classification": "High BP - Stage 2",
    "Data": ""
  },
  {
    "Id": 52,
    "Date": "22-Sep-2022",
    "Time": "08:23 AM",
    "Systolic": "147 mmHg",
    "Diastolic": "97 mmHg",
    "Pulse": "61 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 53,
    "Date": "22-Sep-2022",
    "Time": "11:10 PM",
    "Systolic": "148 mmHg",
    "Diastolic": "99 mmHg",
    "Pulse": "83 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 54,
    "Date": "22-Sep-2022",
    "Time": "11:11 PM",
    "Systolic": "145 mmHg",
    "Diastolic": "97 mmHg",
    "Pulse": "83 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 55,
    "Date": "22-Sep-2022",
    "Time": "11:12 PM",
    "Systolic": "140 mmHg",
    "Diastolic": "95 mmHg",
    "Pulse": "82 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 56,
    "Date": "23-Sep-2022",
    "Time": "11:32 AM",
    "Systolic": "146 mmHg",
    "Diastolic": "95 mmHg",
    "Pulse": "68 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 57,
    "Date": "23-Sep-2022",
    "Time": "11:33 AM",
    "Systolic": "142 mmHg",
    "Diastolic": "94 mmHg",
    "Pulse": "67 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 58,
    "Date": "23-Sep-2022",
    "Time": "11:34 AM",
    "Systolic": "139 mmHg",
    "Diastolic": "97 mmHg",
    "Pulse": "70 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 59,
    "Date": "23-Sep-2022",
    "Time": "09:50 PM",
    "Systolic": "143 mmHg",
    "Diastolic": "95 mmHg",
    "Pulse": "76 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 60,
    "Date": "23-Sep-2022",
    "Time": "09:51 PM",
    "Systolic": "146 mmHg",
    "Diastolic": "92 mmHg",
    "Pulse": "72 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 61,
    "Date": "23-Sep-2022",
    "Time": "09:52 PM",
    "Systolic": "142 mmHg",
    "Diastolic": "93 mmHg",
    "Pulse": "74 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 62,
    "Date": "29-Sep-2022",
    "Time": "10:52 AM",
    "Systolic": "142 mmHg",
    "Diastolic": "100 mmHg",
    "Pulse": "66 bpm",
    "Classification": "High BP - Stage 2",
    "Data": ""
  },
  {
    "Id": 63,
    "Date": "29-Sep-2022",
    "Time": "10:53 AM",
    "Systolic": "149 mmHg",
    "Diastolic": "100 mmHg",
    "Pulse": "61 bpm",
    "Classification": "High BP - Stage 2",
    "Data": ""
  },
  {
    "Id": 64,
    "Date": "29-Sep-2022",
    "Time": "10:54 AM",
    "Systolic": "147 mmHg",
    "Diastolic": "101 mmHg",
    "Pulse": "66 bpm",
    "Classification": "High BP - Stage 2",
    "Data": ""
  },
  {
    "Id": 65,
    "Date": "29-Sep-2022",
    "Time": "10:22 PM",
    "Systolic": "150 mmHg",
    "Diastolic": "102 mmHg",
    "Pulse": "69 bpm",
    "Classification": "High BP - Stage 2",
    "Data": ""
  },
  {
    "Id": 66,
    "Date": "29-Sep-2022",
    "Time": "10:23 PM",
    "Systolic": "154 mmHg",
    "Diastolic": "101 mmHg",
    "Pulse": "69 bpm",
    "Classification": "High BP - Stage 2",
    "Data": ""
  },
  {
    "Id": 67,
    "Date": "29-Sep-2022",
    "Time": "10:24 PM",
    "Systolic": "153 mmHg",
    "Diastolic": "102 mmHg",
    "Pulse": "67 bpm",
    "Classification": "High BP - Stage 2",
    "Data": ""
  },
  {
    "Id": 68,
    "Date": "02-Oct-2022",
    "Time": "06:32 PM",
    "Systolic": "145 mmHg",
    "Diastolic": "101 mmHg",
    "Pulse": "96 bpm",
    "Classification": "High BP - Stage 2",
    "Data": ""
  },
  {
    "Id": 69,
    "Date": "02-Oct-2022",
    "Time": "10:05 PM",
    "Systolic": "153 mmHg",
    "Diastolic": "100 mmHg",
    "Pulse": "94 bpm",
    "Classification": "High BP - Stage 2",
    "Data": ""
  },
  {
    "Id": 70,
    "Date": "02-Oct-2022",
    "Time": "10:06 PM",
    "Systolic": "150 mmHg",
    "Diastolic": "107 mmHg",
    "Pulse": "95 bpm",
    "Classification": "High BP - Stage 2",
    "Data": ""
  },
  {
    "Id": 71,
    "Date": "02-Oct-2022",
    "Time": "10:07 PM",
    "Systolic": "159 mmHg",
    "Diastolic": "103 mmHg",
    "Pulse": "101 bpm",
    "Classification": "High BP - Stage 2",
    "Data": ""
  },
  {
    "Id": 72,
    "Date": "02-Oct-2022",
    "Time": "10:07 PM",
    "Systolic": "143 mmHg",
    "Diastolic": "96 mmHg",
    "Pulse": "88 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 73,
    "Date": "04-Oct-2022",
    "Time": "11:05 PM",
    "Systolic": "139 mmHg",
    "Diastolic": "96 mmHg",
    "Pulse": "81 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 74,
    "Date": "05-Oct-2022",
    "Time": "08:48 AM",
    "Systolic": "136 mmHg",
    "Diastolic": "93 mmHg",
    "Pulse": "64 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 75,
    "Date": "05-Oct-2022",
    "Time": "08:49 AM",
    "Systolic": "130 mmHg",
    "Diastolic": "93 mmHg",
    "Pulse": "66 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 76,
    "Date": "05-Oct-2022",
    "Time": "08:50 AM",
    "Systolic": "138 mmHg",
    "Diastolic": "93 mmHg",
    "Pulse": "64 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 77,
    "Date": "05-Oct-2022",
    "Time": "10:05 PM",
    "Systolic": "143 mmHg",
    "Diastolic": "93 mmHg",
    "Pulse": "75 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 78,
    "Date": "05-Oct-2022",
    "Time": "10:06 PM",
    "Systolic": "138 mmHg",
    "Diastolic": "92 mmHg",
    "Pulse": "75 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 79,
    "Date": "05-Oct-2022",
    "Time": "10:07 PM",
    "Systolic": "136 mmHg",
    "Diastolic": "93 mmHg",
    "Pulse": "76 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 80,
    "Date": "06-Oct-2022",
    "Time": "07:44 AM",
    "Systolic": "143 mmHg",
    "Diastolic": "94 mmHg",
    "Pulse": "64 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 81,
    "Date": "06-Oct-2022",
    "Time": "07:45 AM",
    "Systolic": "131 mmHg",
    "Diastolic": "93 mmHg",
    "Pulse": "61 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 82,
    "Date": "06-Oct-2022",
    "Time": "07:46 AM",
    "Systolic": "137 mmHg",
    "Diastolic": "98 mmHg",
    "Pulse": "66 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 83,
    "Date": "07-Oct-2022",
    "Time": "12:04 AM",
    "Systolic": "135 mmHg",
    "Diastolic": "93 mmHg",
    "Pulse": "85 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 84,
    "Date": "07-Oct-2022",
    "Time": "08:26 AM",
    "Systolic": "140 mmHg",
    "Diastolic": "96 mmHg",
    "Pulse": "63 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 85,
    "Date": "07-Oct-2022",
    "Time": "08:27 AM",
    "Systolic": "140 mmHg",
    "Diastolic": "97 mmHg",
    "Pulse": "64 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 86,
    "Date": "07-Oct-2022",
    "Time": "08:28 AM",
    "Systolic": "145 mmHg",
    "Diastolic": "96 mmHg",
    "Pulse": "64 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 87,
    "Date": "07-Oct-2022",
    "Time": "11:11 PM",
    "Systolic": "156 mmHg",
    "Diastolic": "96 mmHg",
    "Pulse": "80 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 88,
    "Date": "07-Oct-2022",
    "Time": "11:12 PM",
    "Systolic": "150 mmHg",
    "Diastolic": "93 mmHg",
    "Pulse": "81 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 89,
    "Date": "07-Oct-2022",
    "Time": "11:13 PM",
    "Systolic": "149 mmHg",
    "Diastolic": "92 mmHg",
    "Pulse": "81 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 90,
    "Date": "14-Oct-2022",
    "Time": "11:09 AM",
    "Systolic": "135 mmHg",
    "Diastolic": "94 mmHg",
    "Pulse": "66 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 91,
    "Date": "14-Oct-2022",
    "Time": "11:10 AM",
    "Systolic": "127 mmHg",
    "Diastolic": "92 mmHg",
    "Pulse": "72 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 92,
    "Date": "14-Oct-2022",
    "Time": "11:11 AM",
    "Systolic": "127 mmHg",
    "Diastolic": "92 mmHg",
    "Pulse": "70 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 93,
    "Date": "14-Oct-2022",
    "Time": "11:49 PM",
    "Systolic": "155 mmHg",
    "Diastolic": "99 mmHg",
    "Pulse": "88 bpm",
    "Classification": "High BP - Stage 1",
    "Data": "Irregular Heartbeat"
  },
  {
    "Id": 94,
    "Date": "14-Oct-2022",
    "Time": "11:52 PM",
    "Systolic": "146 mmHg",
    "Diastolic": "100 mmHg",
    "Pulse": "86 bpm",
    "Classification": "High BP - Stage 2",
    "Data": ""
  },
  {
    "Id": 95,
    "Date": "14-Oct-2022",
    "Time": "11:53 PM",
    "Systolic": "147 mmHg",
    "Diastolic": "100 mmHg",
    "Pulse": "86 bpm",
    "Classification": "High BP - Stage 2",
    "Data": ""
  },
  {
    "Id": 96,
    "Date": "20-Oct-2022",
    "Time": "09:40 AM",
    "Systolic": "136 mmHg",
    "Diastolic": "96 mmHg",
    "Pulse": "71 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 97,
    "Date": "20-Oct-2022",
    "Time": "09:42 AM",
    "Systolic": "129 mmHg",
    "Diastolic": "89 mmHg",
    "Pulse": "67 bpm",
    "Classification": "High Normal",
    "Data": ""
  },
  {
    "Id": 98,
    "Date": "20-Oct-2022",
    "Time": "09:43 AM",
    "Systolic": "142 mmHg",
    "Diastolic": "92 mmHg",
    "Pulse": "69 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 99,
    "Date": "20-Oct-2022",
    "Time": "09:44 PM",
    "Systolic": "131 mmHg",
    "Diastolic": "98 mmHg",
    "Pulse": "98 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 100,
    "Date": "20-Oct-2022",
    "Time": "09:45 PM",
    "Systolic": "130 mmHg",
    "Diastolic": "95 mmHg",
    "Pulse": "101 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 101,
    "Date": "20-Oct-2022",
    "Time": "09:46 PM",
    "Systolic": "132 mmHg",
    "Diastolic": "95 mmHg",
    "Pulse": "103 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 102,
    "Date": "31-Oct-2022",
    "Time": "11:26 AM",
    "Systolic": "146 mmHg",
    "Diastolic": "97 mmHg",
    "Pulse": "65 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 103,
    "Date": "31-Oct-2022",
    "Time": "11:27 AM",
    "Systolic": "137 mmHg",
    "Diastolic": "94 mmHg",
    "Pulse": "64 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 104,
    "Date": "31-Oct-2022",
    "Time": "11:28 AM",
    "Systolic": "135 mmHg",
    "Diastolic": "92 mmHg",
    "Pulse": "63 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 105,
    "Date": "31-Oct-2022",
    "Time": "11:56 PM",
    "Systolic": "149 mmHg",
    "Diastolic": "99 mmHg",
    "Pulse": "81 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 106,
    "Date": "31-Oct-2022",
    "Time": "11:57 PM",
    "Systolic": "144 mmHg",
    "Diastolic": "98 mmHg",
    "Pulse": "82 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  },
  {
    "Id": 107,
    "Date": "31-Oct-2022",
    "Time": "11:57 PM",
    "Systolic": "142 mmHg",
    "Diastolic": "99 mmHg",
    "Pulse": "81 bpm",
    "Classification": "High BP - Stage 1",
    "Data": ""
  }
 ];

const Measurement = () => {
   return (
      <div className={classes.measurement}>
         {bloodpressures.map((bp, i) => (
            <div className="row">
               <div className="col border border-dark">{bp.Date}</div>
               <div className="col border border-dark">{bp.Time}</div>
               <div className="col border border-dark">{bp.Systolic}</div>
               <div className="col border border-dark">{bp.Diastolic}</div>
               <div className="col-1 border border-dark">{bp.Pulse}</div>
               <div className="col border border-dark">{bp.Classification}</div>
               <div className="col border border-dark">{bp.Data}</div>
            </div>
         ))}

      </div>
   )
}

export default Measurement